import Moment from "moment";
const formatPretty = (dt) => {
  return Moment.utc(dt).format("YYYY-MM-DD HH:mm:ss");
};

const toISOString = (dt) => {
  return Moment(dt).toISOString();
};

const formatBeauty = (dt) => {
  return Moment(dt).format("YYYY-MM-DD HH:mm:ss");
};

const getCurrentDate = () => {
  return Moment().format("YYYY-MM-DD");
};
const getAddedOneDate = () => {
  return Moment().add(1, "days").format("YYYY-MM-DD");
};

const getLastWeekDate = () => {
  return Moment().add(-7, "days").format("YYYY-MM-DD");
};

const getStartOfDate = (date) => {
  return Moment(date, "YYYY-MM-DD")
    .startOf("day")
    .format("YYYY-MM-DD HH:mm:ss");
};

const getEndOfDate = (date) => {
  return Moment(date, "YYYY-MM-DD").endOf("day").format("YYYY-MM-DD HH:mm:ss");
};

const getMomemt = (date) => {
  return Moment(date);
};

export {
  formatPretty,
  formatBeauty,
  getCurrentDate,
  getAddedOneDate,
  getLastWeekDate,
  getStartOfDate,
  getEndOfDate,
  toISOString,
  getMomemt,
};
