import request from "./request.service";
import { getStartOfDate, getEndOfDate } from "../helpers/dateHelper";
export default {
  getPointTransfer: async function (
    consumerId,
    startDate,
    endDate,
    offset = 0,
    limit = 10
  ) {
    try {
      let startPretty = getStartOfDate(startDate);
      let endPretty = getEndOfDate(endDate);
      const response = await request(
        "/transactions/get_point_transfer",
        "POST",
        {
          consumer_id: `${consumerId}`,
          start_date: startPretty,
          end_date: endPretty,
          offset,
          limit,
        }
      );
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  getTransactions: async function (
    consumerId,
    startDate,
    endDate,
    merchantToken,
    transactionType,
    userType,
    offset,
    limit
  ) {
    try {
      let startPretty = getStartOfDate(startDate);
      let endPretty = getEndOfDate(endDate);
      const response = await request("/transactions/get_transactions", "POST", {
        consumer_id: `${consumerId}`,
        start_date: startPretty,
        end_date: endPretty,
        merchant_token: merchantToken,
        transaction_type: transactionType,
        user_type: userType,
        offset,
        limit,
      });
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  getTransactionsTotal: async function (
    consumerId,
    startDate,
    endDate,
    merchantToken,
    transactionType,
    userType
  ) {
    try {
      let startPretty = getStartOfDate(startDate);
      let endPretty = getEndOfDate(endDate);
      const response = await request(
        "/transactions/get_transactions_total",
        "POST",
        {
          consumer_id: `${consumerId}`,
          start_date: startPretty,
          end_date: endPretty,
          merchant_token: merchantToken,
          transaction_type: transactionType,
          user_type: userType,
        }
      );
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  processTransaction: async function (
    mobile,
    merchantId,
    note,
    ticketNumber,
    amount,
    deductAmount
  ) {
    try {
      const response = await request(
        `/transactions/process_transaction`,
        "POST",
        {
          mobile: `${mobile}`,
          merchant_id: merchantId,
          note: note,
          ticket_number: ticketNumber,
          amount: amount,
          deduct_amount: deductAmount,
        }
      );
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  processTransactionExcel: async function (merchantId, excelData) {
    try {
      const response = await request(
        `/transactions/process_transaction/excel`,
        "POST",
        {
          merchant_id: merchantId,
          data: excelData,
        }
      );
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  getEmployeeTransactions: async function (
    employee,
    startDate,
    endDate,
    offset,
    limit
  ) {
    try {
      let startPretty = getStartOfDate(startDate);
      let endPretty = getEndOfDate(endDate);
      const response = await request(`/employee/transactions`, "POST", {
        employee: employee,
        start_date: startPretty,
        end_date: endPretty,
        offset: offset,
        limit: limit,
      });

      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  downloadTransactions: async function (employee, startDate, endDate) {
    try {
      let startPretty = getStartOfDate(startDate);
      let endPretty = getEndOfDate(endDate);
      const response = await request(
        `/employee/transactions/download`,
        "POST",
        {
          employee: employee,
          start_date: startPretty,
          end_date: endPretty,
        }
      );
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  downloadUserTransactions: async function (
    consumer_id,
    startDate,
    endDate,
    merchant_token,
    transaction_type,
    user_type
  ) {
    try {
      let start_date = getStartOfDate(startDate);
      let end_date = getEndOfDate(endDate);
      const response = await request(
        `/transactions/download_transactions`,
        "POST",
        {
          consumer_id,
          start_date,
          end_date,
          merchant_token,
          transaction_type,
          user_type,
        }
      );
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  getTransactionDetail: async function (receiptId) {
    try {
      const response = await request(`/transactions/${receiptId}`, "GET", {});
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  getFilterMerchants: async function () {
    try {
      const response = await request("/filter/merchant", "GET", {});
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  getFilterMerchantsCRM: async function () {
    try {
      const response = await request("/filter/crm_merchant", "GET", {});
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },

  getTerminals: async function () {
    try {
      const response = await request("/filter/receipt_terminals", "GET");
      console.log(response.data);
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.message);
      }
    } catch (err) {
      throw err;
    }
  },
};
