import request from "./request.service";
import axios from "axios";

export default {
  getGeneralInfo: async function (mobile) {
    try {
      const response = await request(`/deggui_shar/generalInfo/${mobile}`);

      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  getPlayerInfo: async function (consumer_id) {
    try {
      const response = await request(`/deggui_shar/playerInfo/${consumer_id}`);

      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  getActivityLog: async function (
    start_date,
    end_date,
    consumer_id,
    offset,
    limit
  ) {
    try {
      let response = await request(
        `/deggui_shar/activity_log/${consumer_id}?start_date=${start_date}&end_date=${end_date}&offset=${offset}&limit=${limit}`
      );
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  getGameLog: async function (
    consumer_id,
    offset,
    limit,
    start_date,
    end_date
  ) {
    try {
      const response = await request(
        `/deggui_shar/game_log/${consumer_id}?offset=${offset}&limit=${limit}&start_date=${start_date}&end_date=${end_date}`
      );
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },

  giveItem: async function (consumer_id, type, count) {
    try {
      if (!count) {
        throw Error("Тоо оруулна уу");
      }
      const response = await request(`/deggui_shar/giveitem`, "POST", {
        consumer_id: consumer_id.toString(),
        type: type,
        count: count,
      });
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
};
