import { Button, Form, Col, Row } from "react-bootstrap";

function Filter(props) {
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    merchants,
    chosenMerchant,
    setChosenMerchant,
    handleSearch,
    handleSearchTotal,
    transactionType,
    setTransactionType,
    userType,
    setUserType,
  } = props;
  const handleDownload = props.handleDownload || null;
  return (
    <>
      <Form>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>Байгууллага</Form.Label>
            <Form.Control
              as="select"
              value={chosenMerchant}
              className="form-control"
              onChange={(e) => {
                setChosenMerchant(e.target.value);
              }}
            >
              <option key={""} value={""}>
                Бүгд
              </option>
              {merchants.map((merchant) => (
                <option key={merchant._id} value={merchant.token}>
                  {merchant.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Эхлэх огноо</Form.Label>
            <Form.Control
              type="date"
              format="yyyy-mm-dd"
              value={startDate}
              onChange={(e) => {
                setStartDate(e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>Дуусах огноо</Form.Label>
            <Form.Control
              type="date"
              format="yyyy-mm-dd"
              placeholder={endDate || "дуусах огноо"}
              value={endDate}
              onChange={(e) => {
                setEndDate(e.target.value);
              }}
            />
          </Form.Group>
          {transactionType != "cancel" ? (
            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>Гүйлгээний төрөл</Form.Label>
              <Form.Control
                as="select"
                value={transactionType}
                className="form-control"
                onChange={(e) => {
                  setTransactionType(e.target.value);
                }}
              >
                <option key={""} value={""}>
                  Бүгд
                </option>
                <option key={"collect"} value={"collect"}>
                  Цуглуулсан
                </option>
                <option key={"spend"} value={"spend"}>
                  Зарцуулсан
                </option>
                <option key={"return"} value={"return"}>
                  Буцаасан
                </option>
              </Form.Control>
            </Form.Group>
          ) : null}

          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>Хэрэглэгчийн төрөл</Form.Label>
            <Form.Control
              as="select"
              value={userType}
              className="form-control"
              onChange={(e) => {
                setUserType(e.target.value);
              }}
            >
              <option key={"consumer"} value={"consumer"}>
                Хэрэглэгч
              </option>
              <option key={"collective"} value={"collective"}>
                Коллектив
              </option>
            </Form.Control>
          </Form.Group>
        </Form.Row>
        <Button
          variant="primary"
          className="btn btn-sm"
          size="md"
          onClick={handleSearch}
        >
          Хайх
        </Button>
        {transactionType != "cancel" ? (
          <Button
            style={{ marginLeft: "10px" }}
            variant="primary"
            className="btn btn-sm"
            size="md"
            onClick={handleDownload}
          >
            Татах
          </Button>
        ) : null}
      </Form>
    </>
  );
}

export default Filter;
