import { Row, Col, Table, Card, Button } from "react-bootstrap";
import Filter from "./Filter";
import { useState } from "react";
import TransactionTotal from "./TransactionTotal";
import Loading from "components/Util/Loading";
import Pagination from "components/Util/Pagination";
import {
  formatPretty,
  getCurrentDate,
  getLastWeekDate,
} from "../../helpers/dateHelper";
import TransactionService from "services/transaction.service";
import TransactionDetailModal from "components/Modal/TransactionDetailModal";

export default function CancelTransactions(props) {
  const { merchantList, consumer } = props;
  const [startDate, setStartDate] = useState(getLastWeekDate());
  const [endDate, setEndDate] = useState(getCurrentDate());
  const [chosenMerchant, setChosenMerchant] = useState("");
  const [transactionType, setTransactionType] = useState("cancel");
  const [userType, setUserType] = useState("");
  const [total, setTotal] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [pagination, setPagination] = useState();

  const [loading, setLoading] = useState(false);

  const getTransactions = async (offset, limit) => {
    try {
      setLoading(true);
      setTransactions([]);
      setPagination(null);
      let data = await TransactionService.getTransactions(
        consumer._id,
        startDate,
        endDate,
        chosenMerchant,
        transactionType,
        userType,
        offset,
        limit
      );
      setTransactions(data.transactions);
      setPagination(data.pagination);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const handleSearch = async () => {
    try {
      console.log("handler called");
      await getTransactions();
    } catch (err) {
      console.log(err);
    }
  };
  const handleSearchTotal = () => {
    try {
    } catch (err) {}
  };

  return (
    <Row>
      <Col md="12">
        <Card className="strpied-tabled-with-hover">
          <Card.Header>
            <Row>
              <Col sm="8">
                <Card.Title as="h4">Ажилтгүй гүйлгээний түүх</Card.Title>
              </Col>
            </Row>
            <Row>
              <Col sm="8">
                <Filter
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  merchants={merchantList}
                  chosenMerchant={chosenMerchant}
                  setChosenMerchant={setChosenMerchant}
                  transactionType={transactionType}
                  userType={userType}
                  setUserType={setUserType}
                  handleSearch={() => {
                    handleSearch();
                  }}
                  total={total}
                />
              </Col>
              <Col sm="4">
                <TransactionTotal total={total} />
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className="table-full-width table-responsive px-0">
            <Table
              className="table-hover"
              style={{
                fontSize: "12px",
              }}
            >
              <thead>
                <tr>
                  <th className="border-0">Created_at</th>
                  <th className="border-0">Date</th>
                  <th className="border-0">Mobile</th>
                  <th className="border-0">receipt id</th>
                  <th className="border-0">bill number</th>
                  <th className="border-0">merchant</th>
                  <th className="border-0">card number</th>
                  <th className="border-0">terminal</th>
                  <th className="border-0">Percentage</th>
                  <th className="border-0">total point</th>
                  <th className="border-0">spend amount</th>
                  <th className="border-0">total amount</th>
                  <th className="border-0">bonus amount</th>
                  <th className="border-0">merchant point</th>
                  <th className="border-0">items point</th>
                  <th className="border-0">before balance</th>
                  <th className="border-0">point balance</th>
                  <th className="border-0">is return</th>
                  <th className="border-0">family_id</th>
                  <th className="border-0">Univision SubId</th>
                </tr>
              </thead>

              <tbody style={{ wordBreak: "break-all" }}>
                {transactions?.map((transaction, ind) => (
                  <>
                    {transaction?.receipt_return ? (
                      <>
                        <tr
                          key={transaction?.receipt_return?._id}
                          className={"table-return"}
                        >
                          <td>
                            {formatPretty(
                              transaction?.receipt_return?.created_at
                            )}
                          </td>
                          <td></td>
                          <td>{transaction.mobile}</td>
                          <td>
                            <TransactionDetailModal
                              receiptId={transaction?._id}
                            />
                          </td>
                          <td>
                            {transaction?.receipt_return?.bill_number || ""}
                          </td>
                          <td>{transaction.merchant_name || ""}</td>
                          <td>{transaction.card_number || ""}</td>
                          <td>{transaction.terminal}</td>
                          <td>
                            {(() => {
                              const percent = Math.round(
                                (transaction.total_point /
                                  transaction.bonus_amount) *
                                  100
                              );
                              console.log(percent);
                              if (
                                percent == Infinity ||
                                (transaction.total_point == 0 &&
                                  transaction.bonus_amount == 0) ||
                                transaction.return_receipt?._id
                              ) {
                                return <></>;
                              }
                              return percent + "%";
                            })()}
                          </td>
                          <td style={{ color: "green" }}>
                            <i className="fa fa-angle-up fa-lg"></i>
                            {transaction?.receipt_return?.total_point}
                          </td>
                          <td style={{ color: "red" }}>
                            <i className="fa fa-angle-down fa-lg"></i>
                            {transaction?.receipt_return?.refund_spend_amount}
                          </td>
                          <td>
                            {transaction?.receipt_return?.refund_cash_amount}
                          </td>
                          <td>
                            {transaction?.receipt_return?.refund_bonus_amount}
                          </td>
                          <td>
                            {transaction?.receipt_return?.merchant_point || 0}
                          </td>
                          <td>
                            {transaction?.receipt_return
                              ?.manufacturer_items_point || 0}
                          </td>
                          <td>
                            {transaction?.receipt_return?.before_balance || ""}
                          </td>
                          <td>{transaction?.receipt_return?.point_balance}</td>
                          <td>{transaction.is_return}</td>
                          <td>{transaction.family_id}</td>
                          <td>{""}</td>
                        </tr>
                      </>
                    ) : (
                      <></>
                    )}
                    {transaction?.receipt_cancel ? (
                      <>
                        <tr
                          key={transaction?.receipt_cancel?._id}
                          className={"table-cancel"}
                        >
                          <td>
                            {formatPretty(
                              transaction?.receipt_cancel?.created_at
                            )}
                          </td>
                          <td></td>
                          <td>{transaction.mobile}</td>
                          <td>
                            <TransactionDetailModal
                              receiptId={transaction?._id}
                            />
                          </td>
                          <td>
                            {transaction?.receipt_cancel?.bill_number || ""}
                          </td>
                          <td>{transaction.merchant_name || ""}</td>
                          <td>{transaction.card_number || ""}</td>
                          <td>{transaction.terminal}</td>
                          <td>
                            {(() => {
                              const percent = Math.round(
                                (transaction.total_point /
                                  transaction.bonus_amount) *
                                  100
                              );
                              console.log(percent);
                              if (
                                percent == Infinity ||
                                (transaction.total_point == 0 &&
                                  transaction.bonus_amount == 0) ||
                                transaction?.receipt_cancel?._id
                              ) {
                                return <></>;
                              }
                              return percent + "%";
                            })()}
                          </td>
                          <td style={{ color: "green" }}>
                            <i className="fa fa-angle-up fa-lg"></i>
                            {transaction?.receipt_cancel?.total_point}
                          </td>
                          <td style={{ color: "red" }}>
                            <i className="fa fa-angle-down fa-lg"></i>
                            {transaction?.receipt_cancel?.refund_spend_amount}
                          </td>
                          <td>
                            {transaction?.receipt_cancel?.refund_cash_amount}
                          </td>
                          <td>
                            {transaction?.receipt_cancel?.refund_bonus_amount}
                          </td>
                          <td>
                            {transaction?.receipt_cancel?.merchant_point || 0}
                          </td>
                          <td>
                            {transaction?.receipt_cancel
                              ?.manufacturer_items_point || 0}
                          </td>
                          <td>
                            {transaction?.receipt_cancel?.before_balance || ""}
                          </td>
                          <td>{transaction?.receipt_cancel?.point_balance}</td>
                          <td>{transaction.is_return}</td>
                          <td>{transaction.family_id}</td>
                          <td>{""}</td>
                        </tr>
                      </>
                    ) : (
                      <></>
                    )}
                    <tr
                      key={transaction._id}
                      className={
                        transaction.is_return == 1 ? "table-danger" : ""
                      }
                    >
                      <td>{formatPretty(transaction.created_at)}</td>
                      <td>{formatPretty(transaction?.date)}</td>
                      <td>{transaction.mobile}</td>
                      <td>
                        <TransactionDetailModal receiptId={transaction._id} />
                      </td>
                      <td>{transaction.bill_number || ""}</td>
                      <td>{transaction.merchant_name || ""}</td>
                      <td>{transaction.card_number || ""}</td>
                      <td>{transaction.terminal}</td>
                      <td>
                        {(() => {
                          const percent = Math.round(
                            (transaction.total_point /
                              transaction.bonus_amount) *
                              100
                          );
                          console.log(percent);
                          if (
                            percent == Infinity ||
                            (transaction.total_point == 0 &&
                              transaction.bonus_amount == 0) ||
                            transaction.return_receipt?._id
                          ) {
                            return <></>;
                          }
                          return percent + "%";
                        })()}
                      </td>
                      <td style={{ color: "green" }}>
                        <i className="fa fa-angle-up fa-lg"></i>
                        {transaction.total_point}
                      </td>
                      <td style={{ color: "red" }}>
                        <i className="fa fa-angle-down fa-lg"></i>
                        {transaction.spend_amount}
                      </td>
                      <td>{transaction.total_amount}</td>
                      <td>{transaction.bonus_amount}</td>
                      <td>{transaction.merchant_point}</td>
                      <td>{transaction.manufacturer_items_point}</td>
                      <td>{transaction.before_balance || ""}</td>
                      <td>{transaction.point_balance}</td>
                      <td>{transaction.is_return}</td>
                      <td>{transaction.family_id}</td>
                      <td>{transaction.univision_subid || ""}</td>
                    </tr>
                  </>
                ))}
              </tbody>
            </Table>
            <Loading loading={loading} />
            <Pagination pagination={pagination} onChange={getTransactions} />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
