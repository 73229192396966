import { Row, Col, Table, Button, Card, Tab, Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import { getLastWeekDate, getAddedOneDate } from "helpers/dateHelper";
import Pagination from "components/Util/Pagination";
import moment from "moment";
import TransactionService from "services/transaction.service";
import newyear2023Service from "services/newyear2023.service";
import GenericModal from "components/Modal/GenericModalNote";

export default function NewYear2023(props) {
  const [startDate, setStartDate] = useState(getLastWeekDate());
  const [endDate, setEndDate] = useState(getAddedOneDate());
  const [mintstartDate, setmintStartDate] = useState(getLastWeekDate());
  const [mintendDate, setmintEndDate] = useState(getAddedOneDate());
  const [gamestartDate, setgameStartDate] = useState(getLastWeekDate());
  const [gameendDate, setgameEndDate] = useState(getAddedOneDate());

  const [chosenMerchant, setChosenMerchant] = useState("");
  const [merchants, setMerchantList] = useState();

  const [activities, setActivities] = useState();
  const [collection, setCollection] = useState();
  const [activityPagination, setacPagination] = useState();
  const [mintPagination, setmintPagination] = useState();

  const [gamelog, setGamelog] = useState();
  const [gamePagination, setgamePagination] = useState();

  const [playerInfo, setPlayerInfo] = useState();

  const [token, setToken] = useState({
    type: "zurh",
    count: 0,
  });
  const [changed, setChanged] = useState(false);
  useEffect(async () => {
    try {
      let merchants = await TransactionService.getFilterMerchants();
      setMerchantList(merchants);

      if (props?.consumer) {
        setActivities([]);
        // getGames();
      }
    } catch (err) {
      console.log(err);
    }
  }, [props?.consumer]);

  useEffect(async () => {
    try {
      if (props?.consumer) {
        setActivities([]);
        getPlayerInfo();
      }
    } catch (error) {}
  }, [props?.consumer, changed]);

  const getCollect = async (offset = 0, limit = 10) => {
    try {
      if (!props?.consumer?.mobile) {
        props.notify("tr", "danger", "Хэрэглэгч хайгаагүй байна.");
      }
      if (props?.consumer?.mobile) {
        const activity_response = await newyear2023Service.getActivityLog(
          new Date(startDate).toISOString(),
          new Date(endDate).toISOString(),
          props.consumer._id,
          offset,
          limit
        );
        setCollection(
          activity_response.data.filter(
            (e) =>
              e.action == "collect" ||
              e.price_channel == "TOKI" ||
              e.price_channel == "UPOINT"
          )
        );
        setacPagination(activity_response.pagination);
        console.log("adtivities1", activity_response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getMintSpend = async (offset = 0, limit = 10) => {
    try {
      setActivities([]);
      if (!props?.consumer?.mobile) {
        props.notify("tr", "danger", "Хэрэглэгч хайгаагүй байна.");
      }
      if (props?.consumer?.mobile) {
        const activity_response = await newyear2023Service.getActivityLog(
          new Date(mintstartDate).toISOString(),
          new Date(mintendDate).toISOString(),
          props.consumer._id,
          offset,
          limit
        );
        setActivities(activity_response.data);
        setmintPagination(activity_response.pagination);
        console.log("adtivities2", activity_response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getGames = async (offset = 0, limit = 10) => {
    try {
      setGamelog([]);
      let gamelog_response = await newyear2023Service.getGameLog(
        props?.consumer?._id,
        offset,
        limit,
        gamestartDate,
        gameendDate
      );
      console.log("gamelog", gamelog_response);
      setGamelog(gamelog_response.data);
      setgamePagination(gamelog_response.pagination);
    } catch (err) {
      console.log(err);
    }
  };
  const getPlayerInfo = async () => {
    try {
      // setPlayerInfo([]);
      const player = await newyear2023Service.getPlayerInfo(
        props?.consumer?._id
      );
      setPlayerInfo(player);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <Col md="12">
        <Card className="striped-tabled-with-hover">
          <Card.Header>
            <Card.Title as="h4">Хэрэглэгчийн одоогийн мэдээлэл</Card.Title>
          </Card.Header>
          <Card.Body className="table-full-width table-responsive px-0">
            <Row>
              <Col md="8">
                <Table
                  className="table-hover"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <thead>
                    <tr>
                      <th className="border-0">Нэр</th>
                      <th className="border-0">Амь</th>
                      <th className="border-0">Бүүст</th>
                      <th className="border-0">Чихэр</th>
                      <th className="border-0">Хамтдаа гишүүн</th>
                      <th className="border-0">Одоогийн өндөр</th>
                      <th className="border-0">Одоогийн байр</th>
                      <th className="border-0">Best score</th>
                      <th className="border-0">Цол</th>
                      <th className="border-0">Өрөө</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{playerInfo?.name}</td>
                      <td>{playerInfo?.zurh}</td>
                      <td>{playerInfo?.boost}</td>
                      <td>{playerInfo?.mint}</td>
                      <td>
                        {playerInfo?.collective
                          ? "Тийм"
                          : playerInfo?.collective == false
                          ? "Үгүй"
                          : ""}
                      </td>
                      <td>{playerInfo?.current_height}</td>
                      <td>{playerInfo?.rank}</td>
                      <td>{playerInfo?.best_score}</td>
                      <td>{playerInfo?.current_tier}</td>
                      <td>{playerInfo?.current_room_shortcode}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      <Col md="12">
        <Row>
          <Col md="6">
            <Card className="striped-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Өдрийн шилдэг</Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Row>
                  <Col md="6">
                    <Table
                      className="table-hover"
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      <thead>
                        <tr>
                          <th className="border-0">Өдрийн Байр</th>
                          <th className="border-0">Үүсэрсэн Өндөр</th>
                          <th className="border-0">Огноо</th>
                        </tr>
                      </thead>
                      <tbody>
                        {playerInfo?.masterboard?.map((el) => (
                          <tr>
                            <td>{el?.rank}</td>
                            <td>{el?.master_score}</td>
                            <td>
                              {new Date(
                                new Date(
                                  el?.created_at.toString()?.slice(0, 10)
                                ).setDate(
                                  new Date(
                                    el?.created_at.toString()?.slice(0, 10)
                                  ).getDate() + 1
                                )
                              )
                                .toISOString()
                                .slice(0, 10)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6">
            <Card className="striped-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Zurh/boost олгох</Card.Title>
              </Card.Header>

              <Card.Body>
                <Form>
                  <Row>
                    <Col md="8">
                      <Form.Group controlId="formGridPassword">
                        <Form.Control
                          as="select"
                          value={token.type}
                          name={"box"}
                          className="form-control"
                          onChange={(e) => {
                            setToken((prevState) => ({
                              ...prevState,
                              type: e.target.value,
                            }));
                          }}
                        >
                          <option key={0} value={"zurh"}>
                            Zurh
                          </option>
                          <option key={1} value={"boost"}>
                            Boost
                          </option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group controlId="formGridEmail">
                        <Form.Label>Нөхөн олгох тоо</Form.Label>
                        <Form.Control
                          type="number"
                          name={"count"}
                          value={token.count}
                          onChange={(event) => {
                            setToken((prevState) => ({
                              ...prevState,
                              count: event.target.value,
                            }));
                          }}
                        />
                      </Form.Group>

                      <GenericModal
                        onClick={async () => {
                          try {
                            const result = await newyear2023Service.giveItem(
                              props.consumer._id,
                              token.type,
                              token.count
                            );
                            setChanged(!changed);
                            props.notify(
                              "tr",
                              "success",
                              `${token.count} ${token.type} олголоо.`
                            );
                          } catch (err) {
                            props.notify("tr", "danger", err.message);
                            console.log(err);
                          }
                        }}
                        params={{}}
                        action={"Эрх олгох"}
                        title={`Та эрх олгохдоо итгэлтэй байна уу.`}
                        body={`Та ${props?.consumer?.mobile} дугаартай хэрэглэгчид эрх олгохдоо итгэлтэй байна уу?`}
                        type={"button"}
                      />
                    </Col>
                    <Col sm="1"></Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Col>

      <Col md="12">
        <Card className="striped-tabled-with-hover">
          <Card.Header>
            <Card.Title as="h4">Хэрэглэгчийн цолтой холбоотой</Card.Title>
          </Card.Header>
          <Card.Body className="table-full-width table-responsive px-0">
            <Row>
              <Col md="1">
                <Table
                  className="table-hover"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <thead>
                    <tr>
                      <th className="border-0">id</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Цол</td>
                    </tr>
                    <tr>
                      <td>Өрөө</td>
                    </tr>
                    <tr>
                      <td>Нийт Оноо</td>
                    </tr>
                    <tr>
                      <td>Байр</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              {[1, 2, 3, 4, 5, 6, 7].map((phase) => (
                <>
                  <Col md="1">
                    <Table
                      className="table-hover"
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      <thead>
                        <tr>
                          <th className="border-0">{phase}-р үе</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {
                              playerInfo?.ranks?.filter(
                                (p) => p.phase == phase
                              )[
                                playerInfo?.ranks?.filter(
                                  (p) => p.phase == phase
                                ).length - 1
                              ]?.prev_tier
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {
                              playerInfo?.ranks?.filter(
                                (p) => p.phase == phase
                              )[
                                playerInfo?.ranks?.filter(
                                  (p) => p.phase == phase
                                ).length - 1
                              ]?.room_shortcode
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {
                              playerInfo?.ranks?.filter(
                                (p) => p.phase == phase
                              )[
                                playerInfo?.ranks?.filter(
                                  (p) => p.phase == phase
                                ).length - 1
                              ]?.height
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {
                              playerInfo?.ranks?.filter(
                                (p) => p.phase == phase
                              )[
                                playerInfo?.ranks?.filter(
                                  (p) => p.phase == phase
                                ).length - 1
                              ]?.rank
                            }
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </>
              ))}
            </Row>
          </Card.Body>
        </Card>
      </Col>

      <Col md="12">
        <Card className="striped-tabled-with-hover">
          <Card.Header>
            <Card.Title as="h4">Эрх Цуглуулалт</Card.Title>
          </Card.Header>

          <Form>
            <Form.Row className="align-items-center">
              <Form.Group as={Col} sm="2" controlId="formGridEmail">
                <Form.Label>Эхлэх огноо</Form.Label>
                <Form.Control
                  type="date"
                  format="yyyy-mm-dd"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group as={Col} sm="2" controlId="formGridPassword">
                <Form.Label>Дуусах огноо</Form.Label>
                <Form.Control
                  type="date"
                  format="yyyy-mm-dd"
                  placeholder={endDate || "дуусах огноо"}
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} sm="3" controlId="formGridPassword">
                <Button
                  style={{ marginTop: "20px" }}
                  variant="primary"
                  className="btn btn-sm"
                  size="md"
                  onClick={getCollect}
                >
                  Хайх
                </Button>
              </Form.Group>
            </Form.Row>
          </Form>
          <Row>
            <Col md="8">
              <Table
                className="table-hover"
                style={{
                  fontSize: "12px",
                }}
              >
                <thead>
                  <tr>
                    <th className="border-0">Огноо</th>
                    <th className="border-0">Олголт</th>
                    <th className="border-0">
                      Худалдан авалт хийсэн байгууллага
                    </th>
                    <th className="border-0">Зарцуулалтын төрөл</th>
                    <th className="border-0">Олгосон тоо</th>
                    <th className="border-0">Үнэ</th>
                    <th className="border-0">Төрөл</th>
                    <th className="border-0">Баримтын дугаар</th>
                    <th className="border-0">Мессеж</th>
                  </tr>
                </thead>
                <tbody style={{ wordBreak: "break-all" }}>
                  {collection?.map((log) => (
                    <tr>
                      <td>
                        {moment(log.created_at).format("YYYY-MM-DD HH:mm")}
                      </td>
                      <td>{log.source}</td>
                      <td>{log.merchant}</td>
                      <td>{log.price_channel}</td>
                      <td>{log.multiply}</td>
                      <td>{log.price}</td>
                      <td>{log.type}</td>
                      <td>{log.receipt_id}</td>
                      <td>{log.message ? log.message : ""}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Pagination
                pagination={activityPagination}
                onChange={getCollect}
              />
            </Col>
          </Row>
        </Card>
      </Col>

      <Col md="12">
        <Card className="striped-table-with-hover">
          <Card.Header>
            <Card.Title as="h4">Эрх зарцуулалт /Тоглолтын түүх/</Card.Title>
          </Card.Header>
          <Form>
            <Form.Row className="align-items-center">
              <Form.Group as={Col} sm="2" controlId="formGridEmail">
                <Form.Label>Эхлэх огноо</Form.Label>
                <Form.Control
                  type="date"
                  format="yyyy-mm-dd"
                  placeholder={gameendDate || "эхлэх огноо"}
                  value={gamestartDate}
                  onChange={(e) => {
                    setgameStartDate(e.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group as={Col} sm="2" controlId="formGridPassword">
                <Form.Label>Дуусах огноо</Form.Label>
                <Form.Control
                  type="date"
                  format="yyyy-mm-dd"
                  placeholder={gameendDate || "дуусах огноо"}
                  value={gameendDate}
                  onChange={(e) => {
                    setgameEndDate(e.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group as={Col} sm="3" controlId="formGridPassword">
                <Button
                  style={{ marginTop: "20px" }}
                  variant="primary"
                  className="btn btn-sm"
                  size="md"
                  onClick={getGames}
                >
                  Хайх
                </Button>
              </Form.Group>
            </Form.Row>
          </Form>
          <Row>
            <Col md="12">
              <Table
                className="table-hover"
                style={{
                  fontSize: "12px",
                }}
              >
                <thead>
                  <tr>
                    <th className="border-0">Тоглолсон огноо</th>
                    <th className="border-0">Тоглолтын төлөв</th>

                    <th className="border-0">Үсэрсэн өндөр</th>
                    <th className="border-0">Өндөр before balance</th>
                    <th className="border-0">Өндөр after balance</th>
                    <th className="border-0">Үе</th>

                    <th className="border-0">Цол</th>
                    <th className="border-0">Бүүстлэсэх</th>
                    <th className="border-0">Амилсан</th>
                  </tr>
                </thead>
                <tbody style={{ wordBreak: "break-all" }}>
                  {gamelog?.map((game, ind) => (
                    <tr key={ind}>
                      <td>
                        {moment(game?.created_at).format("YYYY-MM-DD HH:mm")}
                      </td>
                      <td>{game?.status}</td>

                      <td>{game?.height?.collected}</td>
                      <td>{game?.height?.before}</td>
                      <td>{game?.height?.after}</td>
                      <td>{game?.phase}</td>

                      <td>{game?.tier}</td>
                      <td>{game?.boosted ? "Тийм" : "Үгүй"}</td>
                      <td>{game?.revived ? "Тийм" : "Үгүй"}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Pagination pagination={gamePagination} onChange={getGames} />
        </Card>
      </Col>

      <Col md="12">
        <Card className="striped-table-with-hover">
          <Card.Header>
            <Card.Title as="h4">Чихэр цуглуулалт</Card.Title>
          </Card.Header>
          <Form>
            {/* <Form.Row className="align-items-center">
              <Form.Group as={Col} sm="2" controlId="formGridEmail">
                <Form.Label>Эхлэх огноо</Form.Label>
                <Form.Control
                  type="date"
                  format="yyyy-mm-dd"
                  // value={startDate}
                  onChange={(e) => {
                    // setStartDate(e.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group as={Col} sm="2" controlId="formGridPassword">
                <Form.Label>Дуусах огноо</Form.Label>
                <Form.Control
                  type="date"
                  format="yyyy-mm-dd"
                  placeholder={endDate || "дуусах огноо"}
                  // value={endDate}
                  onChange={(e) => {
                    // setEndDate(e.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group as={Col} sm="3" controlId="formGridPassword">
                <Button
                  style={{ marginTop: "20px" }}
                  variant="primary"
                  className="btn btn-sm"
                  size="md"
                  onClick={handleSearch}
                >
                  Хайх
                </Button>
              </Form.Group>
            </Form.Row> */}
          </Form>
          <Row>
            <Col md="12">
              <Table
                className="table-hover"
                style={{
                  fontSize: "12px",
                }}
              >
                <thead>
                  <tr>
                    <th className="border-0">Тоглолсон огноо</th>
                    <th className="border-0">Чихэр before balance</th>
                    <th className="border-0">Цуглуулсан чихэр </th>
                    <th className="border-0">Чихэр after balance</th>

                    <th className="border-0">Үе</th>
                  </tr>
                </thead>
                <tbody style={{ wordBreak: "break-all" }}>
                  {gamelog?.map((game, ind) => (
                    <tr key={ind}>
                      <td>
                        {moment(game?.created_at).format("YYYY-MM-DD HH:mm")}
                      </td>
                      <td>{game?.mint?.before}</td>
                      <td>{game?.mint?.collected}</td>
                      <td>{game?.mint?.after}</td>

                      <td>{game?.phase}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Pagination pagination={gamePagination} onChange={getGames} />
        </Card>
      </Col>

      <Col md="12">
        <Card className="striped-tabled-with-hover">
          <Card.Header>
            <Card.Title as="h4">Чихэр Зарцуулалт</Card.Title>
          </Card.Header>

          <Form>
            <Form.Row className="align-items-center">
              <Form.Group as={Col} sm="2" controlId="formGridEmail">
                <Form.Label>Эхлэх огноо</Form.Label>
                <Form.Control
                  type="date"
                  format="yyyy-mm-dd"
                  placeholder={mintstartDate || "эхлэх огноо"}
                  value={mintstartDate}
                  onChange={(e) => {
                    setmintStartDate(e.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group as={Col} sm="2" controlId="formGridPassword">
                <Form.Label>Дуусах огноо</Form.Label>
                <Form.Control
                  type="date"
                  format="yyyy-mm-dd"
                  placeholder={mintendDate || "дуусах огноо"}
                  value={mintendDate}
                  onChange={(e) => {
                    setmintEndDate(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} sm="3" controlId="formGridPassword">
                <Button
                  style={{ marginTop: "20px" }}
                  variant="primary"
                  className="btn btn-sm"
                  size="md"
                  onClick={getMintSpend}
                >
                  Хайх
                </Button>
              </Form.Group>
            </Form.Row>
          </Form>
          <Row>
            <Col md="8">
              <Table
                className="table-hover"
                style={{
                  fontSize: "12px",
                }}
              >
                <thead>
                  <tr>
                    <th className="border-0">Үүссэн огноо</th>
                    <th className="border-0">Зарцуулсан чихэр</th>
                    <th className="border-0">Худалдан авсан төрөл</th>
                    <th className="border-0">Олголт</th>
                    <th className="border-0">Төрөл</th>
                    {/* <th className="border-0">Дэлгэрэнгүй</th> */}
                  </tr>
                </thead>
                <tbody style={{ wordBreak: "break-all" }}>
                  {activities
                    ?.filter(
                      (e) => e.action == "spend" && e.price_channel == "MINT"
                    )
                    ?.map((log) => (
                      <tr>
                        <td>
                          {moment(log.created_at).format("YYYY-MM-DD HH:mm")}
                        </td>
                        <td>{log.price}</td>
                        <td>{log.price_channel}</td>
                        <td>{log.source}</td>
                        <td>
                          {log.type == "revive"
                            ? "Нэмэлт амь"
                            : log.type == "zurh"
                            ? "Тоглох эрх"
                            : log.type}
                        </td>
                        {/* <td>{log.ui_source}</td> */}
                      </tr>
                    ))}
                </tbody>
              </Table>
              <Pagination pagination={mintPagination} onChange={getMintSpend} />
            </Col>
          </Row>
        </Card>
      </Col>
      {/* <Col md="12">
        <Card className="striped-table-with-hover">
          <Card.Header>
            <Card.Title as="h4">Купонтой холбоотой</Card.Title>
          </Card.Header>
          <Form>
            <Form.Row className="align-items-center">
              <Form.Group as={Col} sm="2" controlId="formGridEmail">
                <Form.Label>Эхлэх огноо</Form.Label>
                <Form.Control
                  type="date"
                  format="yyyy-mm-dd"
                  // value={startDate}
                  onChange={(e) => {
                    // setStartDate(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} sm="2" controlId="formGridPassword">
                <Form.Label>Дуусах огноо</Form.Label>
                <Form.Control
                  type="date"
                  format="yyyy-mm-dd"
                  placeholder={endDate || "дуусах огноо"}
                  // value={endDate}
                  onChange={(e) => {
                    // setEndDate(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} sm="3" controlId="formGridPassword">
                <Button
                  style={{ marginTop: "20px" }}
                  variant="primary"
                  className="btn btn-sm"
                  size="md"
                  onClick={handleSearch}
                >
                  Хайх
                </Button>
              </Form.Group>
            </Form.Row>
          </Form>
          <Row>
            <Col md="12">
              <Table
                className="table-hover"
                style={{
                  fontSize: "12px",
                }}
              >
                <thead>
                  <tr>
                    <th className="border-0">Утасны дугаар</th>
                    <th className="border-0">Купоны нэр</th>
                    <th className="border-0">Хамтрагч байгууллага</th>
                    <th className="border-0">Идэвхжсэн огноо</th>
                    <th className="border-0">Ашигласан огноо</th>
                    <th className="border-0">Дуусах хугацаа</th>
                  </tr>
                </thead>
                <tbody style={{ wordBreak: "break-all" }}>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col md="12">
        <Card className="striped-table-with-hover">
          <Card.Header>
            <Card.Title as="h4">Урамшуулалтай холбоотой</Card.Title>
          </Card.Header>
          <Form>
            <Form.Row className="align-items-center">
              <Form.Group as={Col} sm="2" controlId="formGridEmail">
                <Form.Label>Эхлэх огноо</Form.Label>
                <Form.Control
                  type="date"
                  format="yyyy-mm-dd"
                  // value={startDate}
                  onChange={(e) => {
                    // setStartDate(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} sm="2" controlId="formGridPassword">
                <Form.Label>Дуусах огноо</Form.Label>
                <Form.Control
                  type="date"
                  format="yyyy-mm-dd"
                  placeholder={endDate || "дуусах огноо"}
                  // value={endDate}
                  onChange={(e) => {
                    // setEndDate(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} sm="3" controlId="formGridPassword">
                <Button
                  style={{ marginTop: "20px" }}
                  variant="primary"
                  className="btn btn-sm"
                  size="md"
                  onClick={handleSearch}
                >
                  Хайх
                </Button>
              </Form.Group>
            </Form.Row>
          </Form>
          <Row>
            <Col md="12">
              <Table
                className="table-hover"
                style={{
                  fontSize: "12px",
                }}
              >
                <thead>
                  <tr>
                    <th className="border-0">Утасны дугаар</th>
                    <th className="border-0">Урамшууллын төрөл</th>
                    <th className="border-0">Урамшуулал</th>
                    <th className="border-0">Хамтрагч байгууллага</th>
                    <th className="border-0">Идэвхжсэн огноо</th>
                    <th className="border-0">Ашигласан огноо</th>
                    <th className="border-0">Дуусах хугацаа</th>
                  </tr>
                </thead>
                <tbody style={{ wordBreak: "break-all" }}>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Card>
      </Col> */}
    </>
  );
}
