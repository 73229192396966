import request from "./request.service";

const generalInfo = async () => {
  try {
    const res = await request(
      "/campaign/sloyalty/crm/sub_loyalty24/tier/",
      "GET"
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};
const getConsumer = async (mobile) => {
  try {
    if (!mobile) return;
    const res = await request(
      `/campaign/sloyalty/crm/sub_loyalty24/consumer?phone=${mobile}`,
      "GET"
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};
const getTransaction = async (mobile) => {
  try {
    if (!mobile) return;
    const res = await request(
      `/campaign/sloyalty/crm/sub_loyalty24/transaction/?phone=${mobile}`,
      "GET"
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};
const getProducts = async () => {
  try {
    const res = await request(`/campaign/sloyalty/products`, "GET");
    return res.data;
  } catch (err) {
    throw err;
  }
};
const getCoupons = async (consumer_id) => {
  try {
    if (!consumer_id) return;
    const res = await request(
      `/campaign/sloyalty/coupons?consumer_id=${consumer_id}`,
      "GET"
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

export default {
  generalInfo,
  getProducts,
  getCoupons,
  getConsumer,
  getTransaction,
};
